import axios from "axios";
import { useEffect, useState } from "react";

const useColors = () => {
  const [colors, setColors] = useState([]);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  const setColor = async (color) => {
    await api.put('/colors', { color: color }, { withCredentials: true });
  };

  useEffect(() => {
    const fetchColors = async () => {
      const { data: colors } = await api.get('/colors');
      console.log(colors);
      setColors(colors);
    };

    fetchColors();
    // eslint-disable-next-line
  }, []);

  return { colors, setColor };
}

export default useColors;