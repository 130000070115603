import useColors from "hooks/useColors";
import ColorSwatch from "./ColorSwatch";
import Button from "./Button";
import { useContext, useState } from "react";
import TRex from "./TRex";
import { UserContext } from "contexts/userContext";

const Dashboard = () => {
  const { colors, setColor } = useColors();

  const { userData } = useContext(UserContext);

  const [currColor, setCurrColor] = useState(userData.color);
  const [selColor, setSelColor] = useState(userData.color);

  const saveColor = () => {
    setColor(selColor);
    setCurrColor(selColor);
  }

  const cancelColor = () => {
    setSelColor(currColor);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: 'calc(100% - 48px)',
        height: 'calc(100% - 180px)',
        borderStyle: 'solid',
        borderWidth: 6,
        borderColor: 'white',
        borderRadius: 24,
        padding: 24,
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div>
          <TRex color={selColor} />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            gap: 8,
          }}
        >
          <Button onClick={cancelColor} style={{ width: '100%' }} >Cancel</Button>
          <Button onClick={saveColor} style={{ width: '100%' }} >Save</Button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {colors.map(color => <ColorSwatch key={color} color={color} setColor={() => setSelColor(color)} />)}
      </div>
    </div>
  );
}

export default Dashboard;