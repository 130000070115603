import Dashboard from 'components/Dashboard';
import Toolbar from 'components/Toolbar';

const Home = () => {
  return (
    <div
      style={{
        height: '95%',
        width: '95%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 12,
      }}
    >
      <Toolbar />
      <Dashboard />
    </div>
  );
}

export default Home;