import axios from 'axios';
import { useEffect, useState } from 'react';

const useUserData = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });

  const logout = async () => {
    await api.get('/logout', { withCredentials: true })
      .finally(() => {
        setUserData(null);
      });
  }

  useEffect(() => {
    const fetchUserData = async () => {
      const {data: userData} = await api.get('/user', { withCredentials: true });
      setUserData(userData);
      setLoading(false);
    }

    fetchUserData();
    // eslint-disable-next-line
  }, []);

  return { loading, userData, logout };
}

export default useUserData;