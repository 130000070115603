import { useContext } from 'react';
import Button from './Button';
import { UserContext } from 'contexts/userContext';

const Toolbar = () => {
  const { userData, logout } = useContext(UserContext);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <h1
        style={{
          margin: 0,
        }}
      >
        Welcome, {userData.username}!
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 12,
        }}
      >
        <Button onClick={logout}>
          Logout
        </Button>
        <img style={{width: 100, height: 100}} src={userData.profile_pic} alt='profile-picture' />
      </div>
    </div>
  );
}

export default Toolbar;