import useUserData from "hooks/useUserData";
import { createContext } from "react"

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const { loading, userData, logout } = useUserData();

  return (
    <UserContext.Provider value={{loading, userData, logout}}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };