import Button from "components/Button";
import twitchLogo from 'assets/TwitchGlitchWhite.svg';
import { useContext } from "react";
import { UserContext } from "contexts/userContext";

const Login = () => {
  const { loading } = useContext(UserContext);

  const handleLogin = () => {
    window.location.href = process.env.REACT_APP_API_URL + '/auth/twitch';
  };

  return (
    <>
      <h1>STREAMPETS</h1>
      {!loading && 
        <Button onClick={handleLogin}>
          <img
            src={twitchLogo}
            alt="Twitch Logo"
            style={{
              width: 30,
              height: 30,
              marginTop: 2,
            }}
            />
          Log in with Twitch
        </Button>
      }
    </>
  )
}

export default Login;