import Home from 'views/Home';
import './App.css';
import Login from 'views/Login';
import { useContext } from 'react';
import { UserContext } from 'contexts/userContext';

function App() {
  const { userData } = useContext(UserContext);

  return (
    <div className="App">
      <header className="App-header">
        {!userData
        ? <Login />
        : <Home />}
      </header>
    </div>
  );
}

export default App;